import { Props, Log, Message, Render, RenderConfig, CSS } from "./type"
import {
    addStyle,
    buildBox,
    buildHeader,
    buildIframe,
    buildModalConfig,
    buildPopupButton,
    buildPopupPanel,
    buildPopupPanelConfig,
    eraseCookie,
    getCookie,
    isFunction,
    logger,
    mergeDeep,
    setCookie,
    buildHeaderConfig,
    isMobile,
} from "./utilities"
import { defaultInline } from "./defaults/inline"
import { defaultPopup } from "./defaults/popup"
import { defaultModal } from "./defaults/modal"

export default class AirkitWebSDK {
    public readonly config: Props
    public popupPanel: HTMLDivElement
    public popupBtn: HTMLDivElement
    public testId: string
    public className: string
    public sessionId: string
    public iframe: HTMLIFrameElement
    public iframeSource: string
    public instanceId: string
    public mobileWidth: number
    public container: string | HTMLElement
    public track: (log: Log) => void
    public url: string
    public options: Record<string, unknown>
    public modalBtn: HTMLDivElement
    public modalContainer: HTMLDivElement
    public inlineContainer: HTMLDivElement
    public bearerToken?: string
    public loadingStatus: "notStarted" | "inProgress" | "done"
    public maximumLoadDelay: number
    public clearResizeListener: () => void
    public clearScrollListener: undefined | (() => void)

    constructor(props: Props) {
        this.config = props
        this.testId = props.testIdPrefix || "airkit"
        this.className = props.classNamePrefix || "airkit"
        this.sessionId = ""
        this.iframeSource = ""
        this.instanceId = props.instanceId || (Math.random() + 1).toString(36).substring(2)
        this.mobileWidth = 700
        this.container = ""
        this.track = logger
        this.config.renderMode ||= "popup"
        this.bearerToken = props.bearerToken
        this.loadingStatus = "notStarted"
        this.maximumLoadDelay = props.maximumLoadDelay || 1000 // Millisecond

        const { renderMode } = this.config
        if (renderMode === "inline") {
            this.config = mergeDeep<Props>(defaultInline, this.config)
        } else if (renderMode === "modal") {
            this.config = mergeDeep<Props>(defaultModal, this.config)
        } else {
            this.config = mergeDeep<Props>(defaultPopup, this.config)
        }
        if (!window.location.protocol.startsWith("https")) {
            this.log({ type: "error", text: "Development on http is unsupported" })
        }
    }

    private log(message: Log) {
        if (this.track) {
            this.track(message)
        }
    }

    private updateButton(
        renderConfig: RenderConfig,
        button: HTMLDivElement,
        action: "open" | "close" | "loading",
    ): void {
        const btnConfig = renderConfig.button
        const srcMap = {
            open: btnConfig.openIconSrc,
            close: btnConfig.closeIconSrc,
            loading: btnConfig.loaderIconSrc,
        }
        const src = srcMap[action]
        let text: string
        if (renderConfig.button.typography) {
            const textMap = {
                open: btnConfig.typography.openText,
                close: btnConfig.typography.closeText,
                loading: btnConfig.typography.loadingText,
            }
            text = textMap[action]
        }
        const img = button.getElementsByTagName("img")[0]
        const span = button.getElementsByTagName("span")[0]
        if (img && src) {
            img.setAttribute("src", src)
        }
        if (span && text) {
            span.innerHTML = text
        }
    }

    private handleLoading(callBackFunc: () => void) {
        eraseCookie(this.instanceId)
        setCookie(this.instanceId, this.sessionId, 1)
        this.iframe.setAttribute("src", this.iframeSource)
        const withTimers = () => {
            removeListener()
            clearTimeout(timeId)
            this.loadingStatus = "done"
            callBackFunc()
        }
        const timeId = setTimeout(withTimers, this.maximumLoadDelay)
        const removeListener = this.on("RUNTIME_READY", withTimers)
    }

    private mobileViewStyle(panel: HTMLDivElement, panelStyle: CSS) {
        addStyle(panel, panelStyle)
        if (this.config.headerConfig) {
            const header = panel.firstChild as HTMLDivElement
            const headerConfig = buildHeaderConfig(this.config.headerConfig, this.config.renderMode, this.mobileWidth)
            addStyle(header, headerConfig.headerStyle)
        }
    }

    private openPopupPanel(): void {
        const renderConfig = buildPopupPanelConfig(this.config.renderConfig, this.mobileWidth)
        const openPanel = () => {
            const finalPanelStyle = {
                ...renderConfig.panel.style,
                height: renderConfig.panel.height,
                width: renderConfig.panel.width,
            }
            this.mobileViewStyle(this.popupPanel, finalPanelStyle)
            this.updateButton(renderConfig, this.popupBtn, "open")
            this.popupBtn.classList.replace("close", "open")
            this.popupPanel.style.setProperty("opacity", "1")
        }
        this.clearResizeListener = this.registerResizeEvent()
        this.clearScrollListener = this.registerScrollEvent()

        if (!this.iframeSource || this.loadingStatus !== "done") {
            this.updateButton(renderConfig, this.popupBtn, "loading")
            if (this.iframeSource) {
                this.handleLoading(openPanel)
            } else {
                this.loadSession(this.url, this.options, this.handleLoading.bind(this, openPanel))
            }
        } else {
            openPanel()
        }
    }

    private closePopupPanel(): void {
        this.clearResizeListener()
        this.clearScrollListener()
        const renderConfig = this.config.renderConfig
        this.updateButton(renderConfig, this.popupBtn, "close")
        this.popupBtn.classList.replace("open", "close")
        this.popupPanel.style.setProperty("height", "0px")
        this.popupPanel.style.setProperty("width", "0px")
        this.popupPanel.style.setProperty("opacity", "0")
    }

    private registerResizeEvent(): () => void {
        const cb = this.handleResize.bind(this)
        window.addEventListener('resize', cb)
        return () => window.removeEventListener('resize', cb)
    }

    private registerScrollEvent(): () => void {
        const body = document.body
        const originalStyle = body.style.overflow || "auto"
        const cb = () => {
            if(isMobile(this.mobileWidth)) {
                addStyle(body, {overflow: "hidden"})
            } else {
                addStyle(body, {overflow: originalStyle})
            }
        }
        window.addEventListener('scroll', cb)
        return () => {
            addStyle(body, {overflow: originalStyle})
            window.removeEventListener('scroll', cb)
            this.clearScrollListener = undefined
        }
    }

    private handleResize(): void {
        if(!isMobile(this.mobileWidth)) {
            this.clearScrollListener()
        } else if(!this.clearScrollListener){
            this.clearScrollListener = this.registerScrollEvent()
        }
        const renderConfig = buildPopupPanelConfig(this.config.renderConfig, this.mobileWidth)
        const finalPanelStyle = {
            ...renderConfig.panel.style,
            ...{opacity: "1"},
            height: renderConfig.panel.height,
            width: renderConfig.panel.width,
        }
        this.mobileViewStyle(this.popupPanel, finalPanelStyle)
    }

    private renderInline() {
        const renderConfig = this.config.renderConfig
        const { containerStyle } = renderConfig
        const parent = typeof this.container === "string" ? document.getElementById(this.container) : this.container
        if (parent) {
            this.inlineContainer = buildBox(containerStyle, `${this.testId}-container`, `${this.className}-container`)
            if (this.config.headerConfig) {
                this.inlineContainer.appendChild(
                    buildHeader(
                        this.config.headerConfig,
                        this.config.renderMode,
                        this.mobileWidth,
                        this.testId,
                        this.className,
                        this.log.bind(this),
                        this.closePopupPanel.bind(this),
                    ),
                )
            }
            this.iframe = buildIframe(
                this.config.renderMode,
                this.mobileWidth,
                this.config.iframeConfig,
                this.iframeSource,
                this.bearerToken,
            )
            this.inlineContainer.appendChild(this.iframe)
            parent.appendChild(this.inlineContainer)
        } else {
            this.log({
                text: `Container ${this.container} does not exist. Please check the container value passed to render method`,
                type: "error",
            })
        }
    }

    private renderPopup() {
        const renderConfig = this.config.renderConfig
        const container = buildBox(
            renderConfig.containerStyle,
            `${this.testId}-container`,
            `${this.className}-container`,
        )
        this.popupBtn = buildPopupButton(
            renderConfig,
            this.testId,
            this.className,
            this.log.bind(this),
            this.openPopupPanel.bind(this),
            this.closePopupPanel.bind(this),
        )
        this.popupPanel = buildPopupPanel(this.config.renderConfig, this.mobileWidth, this.testId, this.className)
        if (this.config.headerConfig) {
            this.popupPanel.appendChild(
                buildHeader(
                    this.config.headerConfig,
                    this.config.renderMode,
                    this.mobileWidth,
                    this.testId,
                    this.className,
                    this.log.bind(this),
                    this.closePopupPanel.bind(this),
                ),
            )
        }
        this.iframe = buildIframe(
            this.config.renderMode,
            this.mobileWidth,
            this.config.iframeConfig,
            this.iframeSource,
            this.bearerToken,
        )
        this.popupPanel.appendChild(this.iframe)
        container.appendChild(this.popupPanel)
        container.appendChild(this.popupBtn)
        document.body.appendChild(container)
    }

    private appendModal() {
        const renderConfig = this.config.renderConfig
        const { containerStyle } = renderConfig
        const modalStyle = buildModalConfig(renderConfig, this.mobileWidth)
        if (this.modalContainer) {
            const modal = this.modalContainer.firstChild as HTMLDivElement
            this.mobileViewStyle(modal, modalStyle)
            return
        }

        this.modalContainer = buildBox(containerStyle, `${this.testId}-container`, `${this.className}-container`)
        this.modalContainer.addEventListener("click", this.closeModalPanel.bind(this))

        const modal = buildBox(modalStyle, `${this.testId}-moodal`, `${this.className}-modal`)
        if (this.config.headerConfig) {
            modal.appendChild(
                buildHeader(
                    this.config.headerConfig,
                    this.config.renderMode,
                    this.mobileWidth,
                    this.testId,
                    this.className,
                    this.log.bind(this),
                    this.closeModalPanel.bind(this),
                ),
            )
        }
        this.iframe = buildIframe(
            this.config.renderMode,
            this.mobileWidth,
            this.config.iframeConfig,
            this.iframeSource,
            this.bearerToken,
        )
        modal.appendChild(this.iframe)
        this.modalContainer.appendChild(modal)
        addStyle(this.modalContainer, { display: "none" })
        document.body.appendChild(this.modalContainer)
    }

    private openModalPanel() {
        const renderConfig = this.config.renderConfig
        this.updateButton(renderConfig, this.modalBtn, "open")
        this.modalBtn.classList.replace("close", "open")
        this.appendModal()
        const openModal = () => addStyle(this.modalContainer, { display: "flex" })
        if (!this.iframeSource || this.loadingStatus !== "done") {
            this.updateButton(renderConfig, this.modalBtn, "loading")
            if (this.iframeSource) {
                this.handleLoading(openModal)
            } else {
                this.loadSession(this.url, this.options, this.handleLoading.bind(this, openModal))
            }
        } else {
            addStyle(this.modalContainer, { display: "flex" })
        }
    }

    private closeModalPanel() {
        const renderConfig = this.config.renderConfig
        this.updateButton(renderConfig, this.modalBtn, "close")
        this.modalBtn.classList.replace("open", "close")
        addStyle(this.modalContainer, { display: "none" })
    }

    private renderModal() {
        const renderConfig = this.config.renderConfig
        const parent = typeof this.container === "string" ? document.getElementById(this.container) : this.container
        if (parent) {
            this.modalBtn = buildPopupButton(
                renderConfig,
                this.testId,
                this.className,
                this.log.bind(this),
                this.openModalPanel.bind(this),
                this.closeModalPanel.bind(this),
            )
            parent.appendChild(this.modalBtn)
        } else {
            this.log({
                text: `Container ${this.container} does not exist. Please check the container value passed to render method`,
                type: "error",
            })
        }
    }

    private loadSession(url: string, options: Record<string, unknown>, callBackFunc: () => void) {
        if (!window.fetch) {
            this.log({ text: "Fetch is not supported by your browser", type: "error" })
            return
        }
        const sessionId = getCookie(this.instanceId)
        if (!sessionId) {
            this.log({
                text: `No sessionId was found for instanceId: ${this.instanceId}`,
                type: "warn",
            })
        }
        if (typeof options.body === "object") {
            options.body = JSON.stringify(options.body)
        }
        const fetchOptions = mergeDeep<Record<string, unknown>>(
            {
                method: "POST",
                referrerPolicy: "strict-origin-when-cross-origin",
                headers: {
                    "x-airkit-session-id": sessionId,
                    "Content-Type": "application/json",
                    Authorization: this.bearerToken ? `Bearer ${this.bearerToken}` : undefined,
                },
                body: JSON.stringify({ sessionId }),
            },
            options,
        )
        this.loadingStatus = "inProgress"
        window
            .fetch(url, fetchOptions)
            .then((response) => {
                this.sessionId = response.headers.get("x-airkit-session-id")
                this.iframeSource = response.headers.get("x-airkit-session-url")
                if (
                    !this.sessionId ||
                    !this.iframeSource ||
                    this.sessionId.trim() === "" ||
                    this.iframeSource.trim() === ""
                ) {
                    this.log({
                        text: `URL ${url} didn't return sessionId and/or Application URL`,
                        type: "error",
                    })
                } else {
                    callBackFunc()
                }
            })
            .catch((error) => {
                this.log({
                    text: `Failed to load sessionId and application's url from ${url} with error: ${error}`,
                    type: "error",
                })
            })
    }

    private initUI() {
        const { renderMode } = this.config
        eraseCookie(this.instanceId)
        setCookie(this.instanceId, this.sessionId, 1)
        if (renderMode === "inline") {
            this.renderInline()
        } else if (renderMode === "popup") {
            this.renderPopup()
        } else {
            this.renderModal()
        }
    }

    public dispatch(message: Message): void {
        this.iframe.contentWindow.postMessage(message, this.iframeSource)
    }

    public on(eventSourceKey: string, cb: (event: MessageEvent<Message>) => void): () => void {
        const onMessage = (event: MessageEvent<Message>) => {
            if (
                this.iframe &&
                event.source === this.iframe.contentWindow &&
                eventSourceKey === event.data.eventSourceKey
            ) {
                cb(event)
            }
        }
        window.addEventListener("message", onMessage)
        return () => window.removeEventListener("message", onMessage)
    }

    public openPanel(): void {
        if (this.config.renderMode === "popup") {
            this.openPopupPanel()
        } else if (this.config.renderMode === "modal") {
            this.openModalPanel()
        } else {
            this.log({
                text: `openPanel API is not available for rendering mode: ${this.config.renderMode}.`,
                type: "error",
            })
        }
    }

    public closePanel(): void {
        if (this.config.renderMode === "popup") {
            this.closePopupPanel()
        } else if (this.config.renderMode === "modal") {
            this.closeModalPanel()
        } else {
            this.log({
                text: `closePanel API is not available for rendering mode: ${this.config.renderMode}.`,
                type: "error",
            })
        }
    }

    public alterBtnText(text: string): void {
        if (this.config.renderMode === "inline") {
            this.log({ text: "alterBtnText API is not available for rendering mode: inline", type: "error" })
            return
        }
        const btn = this.config.renderMode === "popup" ? this.popupBtn : this.modalBtn
        const spanElm = btn.getElementsByTagName("span")[0]

        if (!spanElm || !this.config.renderConfig.button.typography) {
            this.log({
                text: "Button text could not be altered, Initial renderConfig.button.typography is empty",
                type: "error",
            })
        } else {
            spanElm.innerHTML = text
        }
    }

    public alterBtnIcon(src: string): void {
        if (this.config.renderMode === "inline") {
            this.log({ text: "alterBtnIcon API is not available for rendering mode: inline", type: "error" })
            return
        }
        const btn = this.config.renderMode === "popup" ? this.popupBtn : this.modalBtn
        const icon = btn.getElementsByTagName("img")[0]
        if (!icon) {
            this.log({
                text: "Button text could not be altered, Initial renderConfig.button does not have icon config",
                type: "error",
            })
        } else {
            icon.setAttribute("src", src)
        }
    }

    public async render(props: Render) {
        const { url, appURL, sessionId, options = {}, container = "airkit-sdk", track, preLoad = false } = props
        const { renderMode } = this.config
        this.url = url
        this.options = options
        this.container = container
        if (isFunction(track)) {
            this.track = track
        }

        if (appURL && appURL.trim() !== "") {
            if (!sessionId) {
                this.log({ text: `appURL "${appURL}" was provided without sessionId.`, type: "warn" })
            }
            this.sessionId = sessionId || this.instanceId
            this.iframeSource = appURL
            this.initUI()
            if (preLoad === true) {
                this.iframe.setAttribute("src", this.iframeSource)
            }
        } else if (url && url.trim() !== "") {
            if (preLoad === true) {
                this.loadSession(url, options, () => {
                    this.initUI()
                    this.iframe.setAttribute("src", this.iframeSource)
                })
            } else if (renderMode === "inline") {
                this.loadSession(url, options, this.initUI.bind(this))
            } else if (renderMode === "popup") {
                this.renderPopup()
            } else {
                this.renderModal()
            }
        } else {
            this.log({ text: 'Neither "appURL" nor "url" was provided. SDK failed to load.', type: "error" })
        }
    }
}

window.AirkitWebSDK = AirkitWebSDK
