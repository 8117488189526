import { isObject } from "./isObject"

export const mergeDeep = <T>(target: Partial<T>, source: T): T => {
    const merged = Object.assign({}, target)
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(merged, { [key]: source[key] })
                else merged[key] = mergeDeep(target[key], source[key])
            } else {
                Object.assign(merged, { [key]: source[key] })
            }
        })
    }
    return merged as T
}
