import { Props } from "../type"

const CloseIcon = `${process.env.asset_base}/close.svg`

export const defaultModal: Props = {
    iframeConfig: {
        style: {
            width: "100%",
            height: "100%",
            border: "0px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            webkitBorderBottomRightRadius: "10px",
            webkitBorderBottomLeftRadius: "10px",
        },
    },
    renderConfig: {
        containerStyle: {
            position: "fixed",
            inset: "0px",
            zIndex: "1000",
            height: "100%",
            width: "100%",
            backgroundColor: "#5e5c5c45",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
        },
        button: {
            hoverColor: "#ff4e8357",
            buttonStyle: {
                width: "160px",
                height: "61px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                top: "0px",
                bottom: "0px",
                border: "1px solid #f94e83",
            },
            buttonAria: {
                role: "button",
                "aria-label": "Click to toggle popup panel",
            },
            typography: {
                openText: "Click to close",
                closeText: "Click to open",
                loadingText: "Loading ...",
                style: {
                    color: "#f94e83",
                    fontFamily: "Helvetica",
                    fontSize: "16px",
                    marginLeft: "4px",
                },
            },
        },
        panel: {
            width: "800px",
            height: "600px",
            style: {
                webkitBorderRadius: "10px",
                borderRadius: "10px",
                webkitBoxShadow: "#938b8d85 0px 1px 10px 2px",
                boxShadow: "#938b8d85 0px 1px 10px 2px",
                transition: "height 300ms linear 0s, opacity 300ms linear 0s",
                overflow: "hidden",
                zIndex: "1001",
                backgroundColor: "#fff",
            },
        },
    },
    headerConfig: {
        headerStyle: {
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            backgroundColor: "#ff4e83",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            webkitBorderTopRightRadius: "10px",
            webkitBorderTopLeftRadius: "10px",
        },
        actionsBoxStyle: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
        },
        close: {
            src: CloseIcon,
            imageStyle: {
                width: "12px",
                height: "12px",
                margin: "0 auto",
            },
            buttonStyle: {
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                webkitBorderRadius: "8px",
                transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            },
            hoverColor: "#e52b63",
            buttonAria: {
                role: "button",
                "aria-label": "Close popup panel",
            },
            imageAria: {
                role: "presentation",
            },
        },
    },
}
