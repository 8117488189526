import { Log, RenderConfig } from "../type"
import { addStyle } from "./addStyle"
import { addAria } from "./addAria"
import { buildBox } from "./buildBox"
import { buildTypography } from "./buildTypography"

export const buildPopupButton = (
    renderConfig: RenderConfig,
    testId: string,
    className: string,
    log: (log: Log) => void,
    onOpen: () => void,
    onClose: () => void,
): HTMLDivElement => {
    const config = renderConfig.button

    const button = buildBox(config.buttonStyle, `${testId}-popup-button`, `${className}-popup-button close`)
    button.setAttribute("role", "button")
    if (config.buttonAria) {
        addAria(button, config.buttonAria)
    }

    button.addEventListener("pointerenter", (event) => {
        if (!(event.target instanceof HTMLElement)) {
            return
        }
        const elm = event.target
        elm.style.setProperty("background-color", config.hoverColor)
        const spanElm = elm.getElementsByTagName("span")[0]
        if (spanElm && config.typography && config.typography.hoverColor) {
            spanElm.style.color = config.typography.hoverColor
        }
    })
    button.addEventListener("pointerleave", (event) => {
        if (!(event.target instanceof HTMLElement)) {
            return
        }
        const elm = event.target
        elm.style.setProperty("background-color", "unset")
        const spanElm = elm.getElementsByTagName("span")[0]
        if (spanElm && config.typography && config.typography.hoverColor) {
            spanElm.style.color = config.typography.style.color || "inherit"
        }
    })
    button.addEventListener("click", () => {
        log({ text: "Popup button clicked", type: "info" })
        if (button.classList.contains("close")) {
            onOpen()
        } else {
            onClose()
        }
    })

    if (config.closeIconSrc && config.iconStyle) {
        const img = document.createElement("img")
        img.setAttribute("src", config.closeIconSrc)
        addStyle(img, config.iconStyle)
        if (config.iconAria) {
            addAria(img, config.iconAria)
        }
        button.appendChild(img)
    }

    if (config.typography) {
        button.appendChild(
            buildTypography(
                config.typography.closeText,
                config.typography.style,
                `${testId}-btn-text`,
                `${className}-btn-text`,
            ),
        )
    }

    return button
}
