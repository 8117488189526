import { Props } from "../type"

export const defaultInline: Props = {
    iframeConfig: {
        style: {
            width: "100%",
            height: "100%",
            border: "0px",
        },
    },
    renderConfig: {
        containerStyle: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
    },
}
