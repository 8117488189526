import { Header, Log, RenderMode } from "../type"
import { buildBox } from "./buildBox"
import { buildIconButton } from "./buildIconButton"
import { buildTypography } from "./buildTypography"
import { isMobile } from "./isMobile"
import { mergeDeep } from "./mergeDeep"

export const buildHeaderConfig = (config: Header, renderMode: RenderMode, mobileWidth: number): Header => {
    if (isMobile(mobileWidth)) {
        return mergeDeep<Header>(config, {
            headerStyle: {
                borderRadius: "0px",
                webkitBorderRadius: "0px",
            },
        })
    }
    return config
}

export const buildHeader = (
    headerConfig: Header,
    renderMode: RenderMode,
    mobileWidth: number,
    testId: string,
    className: string,
    log: (log: Log) => void,
    onClose: () => void,
): HTMLDivElement => {
    const config = buildHeaderConfig(headerConfig, renderMode, mobileWidth)
    const header = buildBox(config.headerStyle || {}, `${testId}-header`, `${className}-header`)
    const leftBox = buildBox(config.trademarkBoxStyle || {}, `${testId}-trademark`, `${className}-trademark`)
    const rightBox = buildBox(config.actionsBoxStyle || {}, `${testId}-actions`, `${className}-actions`)
    let hasLeftBox = false
    let hasRightBox = false
    if (config.logo) {
        leftBox.appendChild(
            buildIconButton(
                config.logo.src,
                config.logo.style,
                {},
                `${testId}-logo`,
                `${className}-logo`,
                "unset",
                config.logo.buttonAria || {},
                config.logo.imageAria || {},
            ),
        )
        hasLeftBox = true
    }
    if (config.typography) {
        leftBox.appendChild(
            buildTypography(
                config.typography.text,
                config.typography.style,
                `${testId}-header-text`,
                `${className}-header-text`,
            ),
        )
        hasLeftBox = true
    }
    if (renderMode === "popup" && config.minimize) {
        const minimizeElm = buildIconButton(
            config.minimize.src,
            config.minimize.imageStyle,
            config.minimize.buttonStyle,
            `${testId}-minimize-button`,
            `${className}-minimize-button`,
            config.minimize.hoverColor,
            config.minimize.buttonAria || {},
            config.minimize.imageAria || {},
        )
        minimizeElm.addEventListener("click", () => {
            onClose()
            log({
                text: "Minimize button on popup panel clicked",
                type: "info",
            })
        })
        rightBox.appendChild(minimizeElm)
        hasRightBox = true
    }
    if ((renderMode === "popup" || renderMode === "modal") && config.close) {
        const closeElm = buildIconButton(
            config.close.src,
            config.close.imageStyle,
            config.close.buttonStyle,
            `${testId}-close-button`,
            `${className}-close-button`,
            config.close.hoverColor,
            config.close.buttonAria || {},
            config.close.imageAria || {},
        )
        closeElm.addEventListener("click", () => {
            onClose()
            log({ text: "Close button on popup panel clicked", type: "info" })
        })
        rightBox.appendChild(closeElm)
        hasRightBox = true
    }
    if (hasLeftBox) {
        header.appendChild(leftBox)
    }
    if (hasRightBox) {
        header.appendChild(rightBox)
    }
    return header
}
