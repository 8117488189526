import { CSS } from "../type"
import { addStyle } from "./addStyle"

export const buildElement = (tag: string, style: CSS, testId: string, className: string): HTMLElement => {
    const elm = document.createElement(tag)
    addStyle(elm, style)
    elm.setAttribute("class", className)
    elm.setAttribute("data-test-id", testId)
    return elm
}
