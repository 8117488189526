import { IFrameConfig, RenderMode } from "../type"
import { addAria } from "./addAria"
import { addStyle } from "./addStyle"
import { isMobile } from "./isMobile"
import { isFunction } from "./isFunction"
import { mergeDeep } from "./mergeDeep"

const buildIframeConfig = (renderMode: RenderMode, mobileWidth: number, config: IFrameConfig): IFrameConfig => {
    if (renderMode === "popup" && isMobile(mobileWidth)) {
        return mergeDeep<IFrameConfig>(config, {
            style: {
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                webkitBorderBottomRightRadius: "0px",
                webkitBorderBottomLeftRadius: "0px",
            },
        })
    }
    return config
}

export const buildIframe = (
    renderMode: RenderMode,
    mobileWidth: number,
    iframeConfig: IFrameConfig,
    iframeSource: string,
    bearerToken?: string,
): HTMLIFrameElement => {
    const frame = document.createElement("iframe")
    const config = buildIframeConfig(renderMode, mobileWidth, iframeConfig)
    if (config.class) {
        frame.setAttribute("class", config.class)
    }
    if (config.id) {
        frame.setAttribute("id", config.id)
    }
    if (config.name) {
        frame.setAttribute("name", config.name)
    }
    if (config.style) {
        addStyle(frame, config.style)
    }
    if (config.referrerPolicy) {
        frame.setAttribute("referrerPolicy", config.referrerPolicy)
    }
    frame.setAttribute("allow", "camera; fullscreen; geolocation;")

    if (bearerToken) {
        // eslint-disable-next-line no-console
        console.log("[Airkit] DEBUG: Bearer Auth in Use")
        const urlDomain = new URL(iframeSource)
        window.addEventListener("message", (m: MessageEvent) => {
            const msg = JSON.parse(m.data)
            // eslint-disable-next-line no-console
            console.log(`[Airkit] DEBUG: ${m.origin} asking for credentials (expecting: ${urlDomain.origin})`)
            if (msg.type === "wait_for_credentials" && m.origin === urlDomain.origin) {
                // eslint-disable-next-line no-console
                console.log(`[Airkit] DEBUG: Providing credentials to Airkit runtime`)
                frame.contentWindow.postMessage(
                    JSON.stringify({ type: "provide_credentials", data: bearerToken }),
                    urlDomain.origin,
                )
            }
        })
    }

    frame.setAttribute("src", iframeSource)
    if (config.aria) {
        addAria(frame, config.aria)
    }
    frame.addEventListener("load", () => {
        if (isFunction(config.onLoad)) {
            config.onLoad()
        }
    })
    return frame
}
