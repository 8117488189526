import { CSS, Aria } from "../type"
import { addAria } from "./addAria"
import { addStyle } from "./addStyle"
import { buildBox } from "./buildBox"

export const buildIconButton = (
    src: string,
    imgStyle: CSS,
    btnStyle: CSS,
    testId: string,
    className: string,
    hoverColor: string | undefined,
    buttonAria: Aria,
    imageAria: Aria,
): HTMLDivElement => {
    const button = buildBox(btnStyle, testId, className)
    button.addEventListener("mouseenter", (event) => {
        ;(event.target as HTMLElement).style.setProperty("background-color", hoverColor || "unset")
    })
    button.addEventListener("mouseleave", (event) => {
        ;(event.target as HTMLElement).style.setProperty("background-color", "unset")
    })

    const img = document.createElement("img")
    img.setAttribute("src", src)
    addStyle(img, imgStyle)
    addAria(img, imageAria)
    addAria(button, buttonAria)
    button.appendChild(img)
    return button
}
