import { RenderConfig, CSS } from "../type"
import { isMobile } from "./isMobile"
import { mergeDeep } from "./mergeDeep"

export const buildModalConfig = (renderConfig: RenderConfig, mobileWidth: number): CSS => {
    const { width, height } = renderConfig.panel
    if (isMobile(mobileWidth)) {
        return mergeDeep<CSS>(renderConfig.panel.style, {
            webkitBorderRadius: "0px",
            borderRadius: "0px",
            height: "100%",
            width: "100%",
            inset: "0px",
        })
    }
    return { ...renderConfig.panel.style, ...{ width, height } }
}
