import { RenderConfig } from "../type"
import { buildBox } from "./buildBox"
import { isMobile } from "./isMobile"
import { buildPopupPanelConfig } from "./buildPopupPanelConfig"

export const buildPopupPanel = (
    config: RenderConfig,
    mobileWidth: number,
    testId: string,
    className: string,
): HTMLDivElement => {
    const renderConfig = buildPopupPanelConfig(config, mobileWidth)
    const style = renderConfig.panel.style
    style.width = isMobile(mobileWidth) ? renderConfig.panel.width : "0px"
    style.height = "0px"
    style.opacity = "0"
    const panel = buildBox(style, `${testId}-panel`, `${className}-panel`)
    return panel
}
