import { RenderConfig } from "../type"
import { isMobile } from "./isMobile"
import { mergeDeep } from "./mergeDeep"
import {convertToNumber} from "./convertToNumber"

export const buildPopupPanelConfig = (config: RenderConfig, mobileWidth: number): RenderConfig => {
    const mobileConfig = {
        panel: {
            height: "100%",
            width: "100%",
            style: {
                position: "fixed",
                bottom: "0",
                right: "0",
                webkitBorderRadius: "0px",
                borderRadius: "0px",
            },
        },
    }
    const desktopTransition = {transition: `width 300ms linear 0s, ${config.panel.style.transition}`}

    const minimumPadding = convertToNumber(config.panel.style.bottom) + convertToNumber(config.button.buttonStyle.height)
    const panelHeight = convertToNumber(config.panel.height)
    const widthIsSmall = isMobile(mobileWidth)
    const heightDelta =  panelHeight - window.innerHeight 
    const heightIsSmall = heightDelta + minimumPadding > 0
    const canGoMax = config.panel.maxHeight ? window.innerHeight - minimumPadding - config.panel.maxHeight > 0 : false
    
    if (widthIsSmall) {
        return mergeDeep<RenderConfig>(config, mobileConfig)
    } else if (heightIsSmall) {
        const newHeight = panelHeight - heightDelta - minimumPadding;
        return mergeDeep<RenderConfig>(config, {panel: {height: newHeight < 100 ? "100px" : `${newHeight}px`, style: desktopTransition}})
    } else if (canGoMax) {
        return mergeDeep<RenderConfig>(config, {panel: {height: `${config.panel.maxHeight}px`, style: desktopTransition}}) 
    }

    // Stretch up as much as available vertical space
    return mergeDeep<RenderConfig>(config, {panel: {height: `${window.innerHeight - minimumPadding}px`, style: desktopTransition}}) 
}
