import { Props } from "../type"

const PopupOpenIcon = `${process.env.asset_base}/close_popup.svg`
const PopupCloseIcon = `${process.env.asset_base}/open_popup.svg`
const CloseIcon = `${process.env.asset_base}/close.svg`
const LoaderIcon = `${process.env.asset_base}/loader.svg`

export const defaultPopup: Props = {
    iframeConfig: {
        style: {
            width: "100%",
            height: "100%",
            border: "0px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            webkitBorderBottomRightRadius: "10px",
            webkitBorderBottomLeftRadius: "10px",
        },
    },
    renderConfig: {
        containerStyle: {
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "1000",
            margin: "0px",
            minHeight: "60px",
            minWidth: "60px",
        },
        button: {
            openIconSrc: PopupOpenIcon,
            closeIconSrc: PopupCloseIcon,
            loaderIconSrc: LoaderIcon,
            hoverColor: "#ff4e8357",
            buttonStyle: {
                width: "61px",
                height: "61px",
                borderRadius: "50%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
                top: "0px",
                bottom: "0px",
            },
            iconStyle: {
                width: "58px",
                height: "58px",
            },
            buttonAria: {
                role: "button",
                "aria-label": "Click to toggle popup panel",
            },
            iconAria: {
                role: "presentation",
            },
        },
        panel: {
            width: "400px",
            height: "564px",
            maxHeight: 564, // Backward Compatibility 
            style: {
                position: "absolute",
                bottom: "80px",
                right: "4px",
                webkitBorderRadius: "10px",
                borderRadius: "10px",
                webkitBoxShadow: "#938b8d4d 0px 1px 25px 2px",
                boxShadow: "#938b8d4d 0px 1px 25px 2px",
                transition: "height 300ms linear 0s, opacity 300ms linear 0s",
                overflow: "hidden",
                zIndex: "1001",
                display: "flex",
                flexDirection: "column"
            },
        },
    },
    headerConfig: {
        headerStyle: {
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            backgroundColor: "#ff4e83",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            webkitBorderTopRightRadius: "10px",
            webkitBorderTopLeftRadius: "10px",
        },
        actionsBoxStyle: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
        },
        close: {
            src: CloseIcon,
            imageStyle: {
                width: "12px",
                height: "12px",
                margin: "0 auto",
            },
            buttonStyle: {
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                webkitBorderRadius: "8px",
                transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            },
            hoverColor: "#e52b63",
            buttonAria: {
                role: "button",
                "aria-label": "Close popup panel",
            },
            imageAria: {
                role: "presentation",
            },
        },
    },
}
